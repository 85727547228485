<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="12"  lg="12" xl="12"  class="d-flex align-items-center justify-content-end px-0" style="margin-bottom:10px">
                <div class="text-right">
                    <CButton 
                        color="danger"
                        class="mr-1"
                        size="sm"
                        @click="onBtnPdf()"
                        >
                        <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                    </CButton>
                    <CButton
                        color="excel"
                        class="mr-1"
                        size="sm"
                        @click="onBtnExport(true)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XLSX
                    </CButton>
                    <CButton
                        color="watch"
                        class="mr-1"
                        size="sm"
                        @click="onBtnExport(false)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; CSV
                    </CButton>
                </div>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CSelect
                    :value.sync="VisitCraneId"
                    v-model.trim="VisitCraneId"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :label="$t('label.crane')"
                    :options="craneListFormatted"
                    @change="getDetailsMobilizedTeamsList"
                />
            </CCol>          
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.date')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.fromDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                <vue-datepicker 
                    type="datetime"
                    header
                    :lang="this.$i18n.locale"
                    :editable="false"
                    :clearable="false"
                    format="DD/MM/YYYY HH:mm"
                    placeholder="DD/MM/YYYY HH:mm"
                    time-title-format="DD/MM/YYYY HH:mm"
                    v-model="searchFilterDate.toDay"
                    :disabled-date="validateDateRange"
                    :append-to-body="false"
                    value-type="format"
                    :show-second="false"
                >
                <template #icon-calendar>
                    <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </CCol>
            <CCol class="col-sm-11 col-lg-auto col-xl-auto center-field mr-0" style="text-align: right;">
                <CButton
                    color="watch"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                    @click="getDetailsMobilizedTeamsList()"
                    >
                    <CIcon name="cil-search" />
                </CButton>
                <CButton
                    color="wipe"
                    class="justify-content-end"
                    size="sm"
                    v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                    @click="refreshComponent()"
                    >
                    <CIcon name="cil-brush-alt" />
                </CButton>
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field  ">
            </CCol> 
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field  ">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"   
                    v-model="search"           
                >
                </CInput>                
            </CCol>
            <CCol sm="5" lg="5" xl="5" class="center-field"></CCol>
            <CCol sm="11" lg="4" xl="4" class="  justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class="  mb-2">
                <div class="flex-grow-1 bd-highlight">
                    <ag-grid-vue
                        v-if="showGrid"
                        style="width: 100%; height: 100vh;"
                        class="ag-theme-alpine"
                        :gridOptions="gridOptions"
                        :defaultColDef="defaultColDef"
                        :localeText="localeText"
                        :columnDefs="columnDefs"
                        :rowData="formatedItems"
                        :suppressRowClickSelection="true"
                        :groupSelectsChildren="true"
                        :enableRangeSelection="true"
                        :pagination="true"
                        :paginationPageSize="paginationPageSize"
                        :paginationNumberFormatter="paginationNumberFormatter"
                        rowSelection="single"
                        @grid-ready="onGridReady"
                    >
                    </ag-grid-vue>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    //data
    function data() {
        return {
            textSearch:'',
            gridOptions: null,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            searchFilterDate:{
                fromDay:"",
                toDay:"",
            },
            rows: [],
            loadingOverlay: false,
            VisitCraneId: '',
            CraneAlias: '',
            craneOptions: [],
            columnDefs: [
                {
                    field: "IdX",
                    headerName: "#",
                    //lockVisible: true,
                    headerClass: 'center-cell',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 70,
                    //pinned: 'left',
                    cellClass: 'text-center bg-cell',
                    filter: 'agTextColumnFilter',
                    //checkboxSelection: true, 
                },
                {
                    field: "BaySlot",
                    headerName: "SLOT",
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 120,
                    //pinned: 'left',
                    cellClass: 'text-center bg-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "ContainerCode",
                    headerName: "CONTAINER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'text-center bg-cell',
                },
                {
                    field: "Size",
                    headerName: "SIZE (FT)",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 170,
                    cellClass: 'text-center',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: "IsoCode",
                    headerName: "ISO CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Status",
                    headerName: "STATUS",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: "LoadPort",
                    headerName: "LOAD PORT",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 170,
                    cellClass: 'text-center',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: "DischargePort",
                    headerName: "DISCHARGE PORT",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 170,
                    cellClass: 'text-center',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: "CraneAlias",
                    headerName: "CRANE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 120,
                    cellClass: 'text-center',
                },
                {
                    field: "ShippingLineCode",
                    headerName: "SHIPPING LINE",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 170,
                    cellClass: 'text-center',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: "DeviceCode",
                    headerName: "DEVICE CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'text-center',
                },
                {
                    field: "MovStowageName",
                    headerName: "STOWAGE MOV",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 170,
                    cellClass: 'text-center',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: "TransaLogin",
                    headerName: "USER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'text-center',
                },
                {
                    field: "TransactionDate",
                    headerName: "TRANSACTION DATE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 200,
                    cellClass: 'text-center',
                },
            ],
            previousDate: new Date(),
            laterDate: new Date(),
            search: '',
            pageSize:'',
        }
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {
            getRowStyle: params => {
                if (params.data.especial) {
                    return { background: params.data.especial };
                }
            },
        };
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }
    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function onBtnPdf(valor){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        if(rowData.length !== 0) {
            //let un = this.unitOptions.filter(ob => ob.value == this.UnitMeasureId)[0].label;
            await this.getPdf(rowData,this.$t('label.mobilizedReport'));
            this.loadingOverlay = false;
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor){
        this.loadingOverlay = true;
        if(this.formatedItems.length !== 0){
            //let un = this.unitOptions.filter(ob => ob.value == this.UnitMeasureId)[0].label;
            this.loadingOverlay = true;
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            if (this.CraneAlias == null || this.CraneAlias =='') this.CraneAlias=this.$t('label.ALL'); 
            await this.getExcel(rowData,this.$t('label.mobilizedReport'),valor,this.CraneAlias, this.searchFilterDate.fromDay, this.searchFilterDate.toDay);
            this.loadingOverlay = false;
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false;   
    }

    function getCraneList() {
        this.$http.get("VisitVesselCrane-list", { VisitId: this.Visit })
        .then(response => {      
            let listado = response.data.data;
            if (listado.length > 0) {
                this.VisitCraneId = listado[0].VisitCraneId;      
                this.craneOptions = listado;
            }            
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }

    async function getDetailsMobilizedTeamsList () {
        this.loadingOverlay = true;
        this.rows = [];
        let listado = Array;
        let dateStart = '';
        let dateEnd = '';

        this.getDateRange();

        dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
        dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);

        let CraneMovilizedJson = {
            VisitId: this.Visit,
            VisitCraneId: this.VisitCraneId,
            DateStart:dateStart,
            DateEnd:dateEnd,
        };
                              
        await this.$http.post("VisitCraneMovilizedCargo-list", CraneMovilizedJson, { root: 'CraneMovilizedJson' })
        .then(response => {
            listado = [...response.data.data];
            let nulo = String(listado[0].Json);
            if (nulo=="null") return false;

            this.rows = listado;
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }


    function refreshComponent() {
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.pageSize="";
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.searchFilterDate.fromDay="";
        this.searchFilterDate.toDay="";
        this.CraneAlias= '';
        this.VisitCraneId= '';
        this.getCraneList();
        this.getDetailsMobilizedTeamsList();
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }
    
    function getDateRange(){
        let currentDate = new Date();

        if(this.itinerarySelected.Ata!=undefined && this.searchFilterDate.fromDay == ''){ 
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
        }

        if(this.itinerarySelected.Ata==undefined && this.searchFilterDate.fromDay == ''){
            this.searchFilterDate.fromDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
            this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
        }

        if(this.itinerarySelected.Atd!=undefined && this.searchFilterDate.toDay == ''){  
            this.searchFilterDate.toDay=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
            this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
        }

        if(this.itinerarySelected.Atd==undefined && this.searchFilterDate.toDay == ''){
            this.searchFilterDate.toDay=DateFormater.formatTimeZoneToDateTime(currentDate);
            this.laterDate = currentDate;
        }
    }

    //computed
    function formatedItems() {
        let computedItems = [];
        let _this = this.$i18n.locale;
        if(this.rows.length !== 0 && this.nulo!="null"){
            if(this.VisitCraneId !== '' || this.VisitCraneId !== null)
                this.CraneAlias = this.rows[0].CraneAlias;

            this.rows.map(function(item){
                computedItems.push({
                    IdX: item.Idx,
                    BaySlot: item.BaySlot ? item.BaySlot : '',
                    ContainerCode: item.ContainerCode,
                    CraneAlias: item.CraneAlias ? item.CraneAlias : '',
                    DeviceName: item.DeviceName ? item.DeviceName : '',
                    DeviceCode: item.DeviceCode ? item.DeviceCode : '',
                    IsoCode: item.IsoCode ? item.IsoCode : '',
                    Status: item.Status ? item.Status : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    MovStowageName: _this=='en' ? item.MovStowageNameEn : item.MovStowageNameEs,
                    Size: item.Size+item.TpCargoCode,
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    TransaLogin: item.TransaLogin ? item.TransaLogin : '',
                    TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
                })
            })
        }

        return computedItems;
    }
    
    function craneListFormatted(){
        let crane = this.craneOptions.map((item) => Object.assign({}, item, {
            value: item.VisitCraneId,
            label: item.CraneAlias,
        }));

        crane.unshift({value:'',label: this.$t('label.ALL')})
        this.VisitCraneId = '';
        return crane;
    }
    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }
    export default {
        name:'details-mobilized-teams-list',
        props:['loading','tabIndexGruas'],
        mixins: [ReportesVisitas, AgGrid],
        data,
        beforeMount,
        methods:{
            onBtnPdf,
            onBtnExport,
            onGridReady,
            getDetailsMobilizedTeamsList,
            getCraneList,
            onQuickFilterChanged,
            validateDateRange,
            refreshComponent,
            onPageSizeChanged, 
            getDateRange
        },
        computed:{
            formatedItems,
            craneListFormatted,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                tabIndex: state => state.visitas.tabIndex,
                dropItemAgenda: state => state.visitas.dropItemAgenda,
                ItineraryId: state => state.visitas.ItineraryId,
                itinerarySelected: state => state.visitas.itinerarySelected,
                user: state => state.auth.user,
                branch: state => state.auth.branch,
                bulkCarrier: state => state.visitas.bulkCarrier,
            }),
        },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
                });
                }
            },
        },
        watch:{
            textSearch,
            tabIndexGruas: async function(val){
                if (val==5 && this.bulkCarrier == 1 && this.Visit != '') {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    }
</script>
<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .container-movimiento{
        background-color:#e0e0e0;
        border: 1px solid black;
        border-radius: 10px;
        display:flex;
        align-items:center;
        justify-content:center;
        .form-group > .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
            padding-right: 10px;
            padding-left: 10px;
        }
    }
    .border-shadow{
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .form-group {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>